<template>
	<section
		class="grid-products-filtering"
		ref="filter"
	>
		<GridProductsHeading
			:heading="content.heading"
			:topLink="topLink"
			class="margin-bottom-m"
			link-right
			v-if="content.heading || topLink"
		/>

		<div
			v-if="!hideAccessoryFilters"
			class="accessory-filters-wrapper"
		>
			<div class="accessory-filters">
				<div class="flex-basis-wrapper">
					<TnParagraph
						size="m"
						responsive
						bold
						>Finn din modell
					</TnParagraph>
				</div>
				<TnDropdownSelect
					:options="deviceFilterCategories"
					v-model="selectedCategory"
					label="Velg kategori"
					placeholder="Velg"
					size="s"
				/>
				<TnDropdownSelect
					:options="computedDeviceFilters"
					v-model="selectedDevice"
					label="Velg modell"
					placeholder="Velg"
					size="s"
					><template
						v-if="selectedCategory && !selectedDevice"
						#warning
						>Vennligst velg din modell</template
					>
				</TnDropdownSelect>
				<div class="flex-basis-wrapper">
					<TnButton
						v-if="selectedDevice"
						iconLeft="undo"
						@click="resetDeviceFilter"
						size="xs"
						tertiary
						>Tilbakestill
					</TnButton>
				</div>
			</div>
		</div>

		<div
			v-if="!hideControls || enablePaymentToggle"
			class="top margin-bottom-l fixed"
			:style="{ top: topOffset }"
		>
			<div
				class="filter-controls left"
				v-if="!hideControls"
			>
				<TnButton
					@click="toggleFilterControls"
					:text="!showFiltering ? `Filter ${selectedFilters}` : `Skjul Filter ${selectedFilters}`"
					:iconLeft="!showFiltering ? 'filter' : 'eye-closed'"
					style="height: 32px !important; align-self: flex-start; flex-shrink: 0"
					tertiary
					class="filter-button"
					size="xs"
					:loading="loading"
				/>
				<div
					class="chips"
					v-if="(visibleFilterTags && visibleFilterTags.length) || (subcategoriesTags && subcategoriesTags.length)"
				>
					<TnChip
						v-for="tag in visibleFilterTags"
						:key="`tag-${tag}`"
						:text="tag.split(':')[1]"
						@click="removeFilterTag(tag)"
						closeable
					/>
					<TnChip
						v-for="tag in filterState.subcategories"
						:key="`tag-${tag}`"
						:text="tag.split(':')[1]"
						@click="toggleSubCategory(tag)"
						closeable
					/>
					<TnChip
						@click="clearFilterTags"
						v-if="visibleFilterTags.length + filterState.subcategories.length > 1"
						text="Fjern alle"
						closeable
					/>
				</div>
				<LazySorting
					@update:modelValue="handleSorting"
					:options="sortingOptions"
					style="margin-left: auto"
				/>
			</div>

			<div class="right">
				<PaymentOptionToggle
					v-if="!hidePaymentOptionToggle"
					:showSwapPriceFirst="useMonthlyPrice"
					v-model:option="paymentOption"
					:category="content?.eCommerceCategory"
				/>
			</div>
		</div>

		<div class="product-wrapper">
			<Transition name="slide-fade">
				<div
					v-if="showFiltering && !hideControls && initialized"
					class="filters"
					:class="{ loading }"
				>
					<TnAccordion
						v-if="computedFilters"
						allItemsOpen
						multiple
					>
						<TnAccordionItem
							v-for="(filter, index) in computedFilters"
							:key="`filter-${index}`"
							size="s"
							uncontained
							:title="`${translate[filter.label] || 'Filter'}
                  ${
										filterTags.filter((f) => f.startsWith(filter.label)).length > 0
											? '(' + filterTags.filter((f) => f.startsWith(filter.label)).length + ')'
											: ''
									}`"
						>
							<SuperFilter
								v-for="(parentCategory, i) in filter.items"
								:key="`filter-tag-${i}`"
								:parentCategory="parentCategory"
								:filterTags="filterTags"
								:filter="filter"
								@change="filterToggled"
								:filterState="filterState"
								:loading="loading"
								:selectedSubcategories="filterState.subcategories"
								@toggleSubCategory="toggleSubCategory"
								@updateSubcategories="updateSubcategories"
								:subcategoriesCache="subcategories"
							/>
						</TnAccordionItem>
					</TnAccordion>
				</div>
			</Transition>

			<div v-if="!loading && total === 0 && selectedDevice">
				<TnParagraph responsive>Vi fant dessverre ingen resultater som passet dette søket.</TnParagraph>
			</div>

			<div
				class="products"
				:class="[productLayoutClasses]"
				v-else
			>
				<template v-for="(dummy, idx) in limitResults">
					<FakeProductCardWrapper
						v-if="showLoading"
						:key="idx"
						class="margin-bottom-xs"
					/>
				</template>
				<template v-for="(product, index) in products">
					<ProductCardWrapper
						v-if="!showLoading"
						:product="product"
						:unVerifiedDeliveryText="unVerifiedDeliveryText"
						:selectedPaymentOption="paymentOption"
						:badgeCategory="badgeCategory"
						:loading="loading"
						:hide-rating="content.hideRating"
						:hide-description="content.hideDescription"
						:key="product.productId"
						:swapSubscriptionDisclaimerText="swapSubscriptionDisclaimerText"
						@click="clickedProduct = index"
						class="margin-bottom-xs"
					></ProductCardWrapper>
				</template>
			</div>
		</div>
		<TnNotification
			v-if="redirect"
			closable
			timeout="3500"
			position="bottom-right"
			title="Vi fant dessverre ikke siden du lette etter."
			>Om dette ikke var det du lette etter, prøv vår søkefunksjon.
		</TnNotification>
		<Pagination
			v-model:numberOfPages="numberOfPages"
			:showPagination="showPagination"
			v-model:page="page"
			v-model:currentPage="currentPage"
		/>
	</section>
</template>

<script>
import EcommerceProductModel from "~/components/ecommerce/GridProducts/models/EcommerceProductModel.js";
import EcommerceService from "~/integrations/ecommerce-v2-integration";
import sortingOptions from "./models/sortBy";
import capitalize from "~/helpers/formatting/capitalize";
import GizmoComponentMixin from "~/mixins/GizmoComponentMixin.js";
import SectionLayout from "~/mixins/SectionLayout";
import LinkViewModel from "~/helpers/ViewModels/LinkViewModel.js";

import { format as formatAxiosError } from "@redtea/format-axios-error";

const getSafeBrandOption = (component) => {
	if (component.content.brand) return component.content.brand;
	if (component.content.eCommerceBrand) return component.content.eCommerceBrand;
	return [];
};

const getSafeCategoryOption = (component) => {
	if (component.content.category) return component.content.category;
	if (component.content.eCommerceCategory) return component.content.eCommerceCategory;
	return [];
};

const getSafeAdditionalfiltersOption = (component) => {
	if (component.content.eCommerceAdditionalFilters) return component.content.eCommerceAdditionalFilters;
	return [];
};

const translate = {
	Brand: "Merke",
	Categorynames: "Kategori",
	Additionalfilters: "Andre",
	Tilbehør: "Kategori",
	Lyd: "Kategori",
	"Brukte mobiler": "Kategori",
	Smartklokker: "Kategori",
	Nettbrett: "Kategori",
	Ruter: "Kategori",
};

export default defineNuxtComponent({
	name: "GridProductsFilter",

	mixins: [GizmoComponentMixin, SectionLayout],

	data() {
		return {
			lastScrollPosition: 0,
			topOffset: "0px",
			showFiltering: false,
			filters: [],
			products: [],
			paymentOption: 0,
			subcategories: {},
			limitResults: this.component?.content?.limitResults || 8,
			featuredItems: this.component?.content?.featuredItems || false,
			total: 0,
			filterState: {
				brand: [...getSafeBrandOption(this.component)],
				categorynames: [...getSafeCategoryOption(this.component)],
				additionalfilters: [...getSafeAdditionalfiltersOption(this.component)],
				subcategories: [],
			},
			deviceFilters: [],
			selectedDevice: "",
			selectedCategory: "",
			sortingOptions: sortingOptions,
			loading: true,
			clickedProduct: -1,
			page: 0,
			sortBy: "",
			translate,
			redirect: false,
			initialized: false,
			filtersFromUrl: false,
		};
	},

	watch: {
		async "$route.query.q"() {
			await this.init();
		},
		async paymentOption() {
			await this.init();
		},
		numberOfPages() {
			if (this.currentPage > this.numberOfPages) {
				this.page = this.numberOfPages - 1;
			}
		},
		async page() {
			await this.init();
			this.scrollToGrid();
		},
		async selectedDevice() {
			this.trackCategoryAndDevice(this.selectedDevice);
			await this.init();
		},
		async selectedCategory() {
			await this.getDeviceFilters();
		},
	},

	methods: {
		handleScroll() {
			if (this.lastScrollPosition < window.scrollY) {
				setTimeout(() => {
					this.topOffset = "0px";
				}, 200);
			} else {
				setTimeout(() => {
					if (this.isMobile) {
						this.topOffset = "60px";
					} else {
						this.topOffset = "80px";
					}
				}, 200);
			}

			this.lastScrollPosition = window.scrollY;
		},
		scrollToGrid() {
			this.$refs.filter?.scrollIntoView({ behavior: "smooth", block: "start" });
		},
		updateSubcategories(value, parent) {
			this.subcategories[parent] = value;
			const selectableSubcategories = value.map((subcategory) => `${parent}:${subcategory.name}`);
			this.filterState.subcategories = this.filterState.subcategories.filter((subcategory) =>
				selectableSubcategories.includes(subcategory),
			);

			this.updateUrl();
		},
		async toggleSubCategory(value) {
			if (this.filterState.subcategories.includes(value)) {
				this.filterState.subcategories = this.filterState.subcategories.filter((x) => x !== value);
			} else this.filterState.subcategories.push(value);
			this.page = 0;
			await this.init();
		},
		async removeFilterTag(tag) {
			this.page = 0;
			const key = tag.split(":")[0].toLowerCase();
			const val = tag.split(":")[1];
			if (!Object.keys(this.filterState).some((filter) => filter === key)) {
				this.toggleSubCategory(tag);
			}
			this.filterState[key] = this.filterState[key].filter((x) => x !== val);
			this.checkSubcategories(val);
			await this.init();
		},
		async clearFilterTags() {
			this.page = 0;

			this.filterState = {
				brand: this.content.hideBrandFilter ? this.filterState.brand : [],
				categorynames: this.content.hideCategoryFilter ? this.filterState.categorynames : [],
				additionalfilters: [],
				subcategories: [],
			};
			this.subcategories = {};
			if (import.meta.client) {
				window.history.replaceState({}, "", `${location.pathname}`);
			}
			await this.init();
		},
		async handleSorting(criteria) {
			this.sortBy = criteria;
			await this.init();
		},
		filterToggled(filterTag) {
			this.page = 0;
			if (typeof filterTag !== "string") return;

			const key = filterTag.split(":")[0].toLowerCase();
			const val = filterTag.split(":")[1];

			if (!Object.keys(this.filterState).some((filter) => filter === key)) {
				return this.toggleSubCategory(filterTag);
			}

			if (!this.filterState[key]) {
				this.filterState[key] = [val];
			} else if (this.filterState[key].includes(val)) {
				this.removeFilterTag(filterTag);
			} else {
				this.filterState[key].push(val);
			}

			this.checkSubcategories(val);
			this.updateUrl();
			this.init();
		},
		updateUrl() {
			let urlString = "?";
			for (const categories in this.filterState) {
				if (this.filterState[categories].length > 0 && urlString.length === 1 && categories !== "categorynames")
					urlString += `${categories}=${this.filterState[categories].join(",")}`;
				// Adds "&" between categories if there are more than one category selected
				else if (this.filterState[categories].length > 0 && urlString.length > 1 && categories !== "categorynames") {
					urlString += `&${categories}=${this.filterState[categories].join(",")}`;
				}
			}
			if (import.meta.client && !this.featuredItems && useRoute()?.name !== "sok") {
				if (urlString.length > 1) window.history.replaceState({}, "", `${location.pathname}${urlString}`);
				else window.history.replaceState({}, "", `${location.pathname}`);
			}
		},
		async setFiltersFromUrl() {
			if (this.featuredItems) {
				return;
			} else if (useRoute()?.name === "sok") {
				return;
			}
			for (const category in useRoute().query) {
				if (category === "cid") {
					return;
				}
				if (category.toLowerCase() === "redirect") {
					this.redirect = true;
				} else {
					this.filterState[category] = useRoute()
						.query[category]?.split(",")
						?.map((item) => capitalize(item));
				}
				this.showFiltering = true;
				this.filtersFromUrl = true;
			}
			await this.init();
		},
		checkSubcategories(val) {
			if (val === "Tilbehør" || val === "Lyd") {
				this.filterState.subcategories = this.filterState.subcategories.filter((x) => !x.includes(val));
				this.subcategories[val] = undefined;
			}
		},
		toggleFilterControls() {
			this.showFiltering = !this.showFiltering;
			this.scrollToGrid();
		},
		async getDeviceFilters() {
			if (this.hideAccessoryFilters) return;
			this.loading = true;
			try {
				this.selectedDevice = "";
				this.deviceFilters = await EcommerceService.getDeviceFilters(
					this.selectedCategory !== ""
						? this.selectedCategory
						: this.deviceFilterCategories.map((x) => x.value).join(","),
				);
			} catch (error) {
				console.error(formatAxiosError(error));
			} finally {
				this.loading = false;
			}
		},
		resetDeviceFilter() {
			this.selectedDevice = "";
			this.selectedCategory = "";
		},
		trackCategoryAndDevice(deviceId) {
			this.$track(
				{
					event_name: "Accessory filter",
					sc_events: { event255: 1 },
					filter_selection: `${this.selectedCategory}${deviceId ? " > " + this.getDeviceNameFromID(deviceId) : ""}`,
				},
				"link",
			);
		},
		getDeviceNameFromID(deviceId) {
			return (
				this.computedDeviceFilters?.find((device) => {
					return device.value === deviceId;
				})?.label || deviceId
			);
		},
		async init() {
			this.loading = true;

			try {
				const translateSegment = { consumer: "privat", business: "bedrift" };
				const segment = translateSegment[this.$store.getters["ecommerce/segment"]];
				const results = await EcommerceService.getFilters(
					segment,
					this.filterState.categorynames?.join(","),
					this.filterState.brand?.join(","),
					useRoute().query.q,
					this.subcategoriesTags.join(","),
					this.filterState.additionalfilters?.join(","),
					this.selectedDevice,
					this.paymentOption === 1 ? "monthly" : undefined,
				);

				if (useRoute()?.name !== "sok") {
					let subcategories = await EcommerceService.getSubcategories(
						segment,
						this.filterState?.categorynames?.join(","),
						this.filterState.brand?.join(","),
						undefined,
						this.selectedDevice,
						this.paymentOption === 1 ? "monthly" : undefined,
					);
					subcategories = subcategories.subcategories.filter((x) => x.name !== this.filterState?.categorynames[0]);
					if (subcategories.length > 0 && this.component?.content?.eCommerceCategory?.length > 0) {
						this.updateSubcategories(subcategories, this.component.content.eCommerceCategory[0]);
					} else if (subcategories.length > 0 && this.filterState?.categorynames?.length > 0) {
						// TODO:- Figure out what the heck was supposed to happen here, looking at you Martin.
					} else if (subcategories.length === 0) {
						this.subcategories = [];
					}
				}

				this.filters = Object.keys(results)
					.filter((key) => {
						if (this.content.hideBrandFilter && key === "brand") return false;
						if (this.content.hideCategoryFilter && key === "categorynames") return false;
						if (this.content.hideAdditionalFilters && key === "additionalfilters") return false;
						return true;
					})
					.map((key) => {
						return {
							label: capitalize(key),
							items: results[key],
						};
					});

				const order = ["Categorynames", "Brand", "Additionalfilters"];
				this.filters = this.filters.sort(function (a, b) {
					return order.indexOf(a.label) - order.indexOf(b.label);
				});

				if (this.filters) {
					const brands = this.filterState.brand.filter(
						(x) =>
							!this.filters
								.filter((x) => x.label === "Brand")[0]
								.items.map((x) => x.name)
								.includes(x),
					);
					// let categorynames = this.filterState.categorynames.filter(x => !this.filters.filter(x => x.label === "Categorynames")[0].items.map(x => x.name).includes(x))
					const additionalfilters = this.filterState.additionalfilters.filter(
						(x) =>
							!this.filters
								.filter((x) => x.label === "Additionalfilters")[0]
								.items.map((x) => x.name)
								.includes(x),
					);
					brands.forEach((brands) => this.removeFilterTag(`Brand:${brands}`));
					// categorynames.forEach(categorynames => this.removeFilterTag(`Categorynames:${categorynames}`))
					additionalfilters.forEach((additionalfilters) =>
						this.removeFilterTag(`Additionalfilters:${additionalfilters}`),
					);
				}
				const productsToPrioritize =
					this.component?.content?.productsToPrioritize?.map((product) => product.product.productId) || [];
				const filteredProducts = await EcommerceService.getFilteredProducts(
					segment,
					this.filterTags,
					this.subcategoriesTags.join(","),
					this.limitResults,
					this.page * this.limitResults,
					this.sortBy,
					useRoute().query.q,
					productsToPrioritize?.join(","),
					this.onlyShowSwap,
					this.onlyPrioritized,
					this.selectedDevice,
					this.paymentOption === 1 ? "monthly" : undefined,
				);

				const { products, total } = filteredProducts;

				this.total = total;

				this.products = products
					?.filter((product) => product)
					.map((product) => {
						return EcommerceProductModel(product, segment, { monthlyPriceFirst: this.useMonthlyPrice });
					});

				if (this.hideOutOfStockProducts) {
					this.products = this.products?.filter((p) => p?.inventory?.[0]?.amount > 0);
				}
			} catch (e) {
				console.error(formatAxiosError(e));
			} finally {
				this.loading = false;
			}
		},
	},

	created() {
		if (import.meta.client) {
			window.addEventListener("scroll", this.handleScroll);
		}
	},
	unmounted() {
		if (import.meta.client) {
			window.removeEventListener("scroll", this.handleScroll);
		}
	},

	async mounted() {
		if (this.useMonthlyPrice) {
			this.paymentOption = 1;
		}
		await this.setFiltersFromUrl();
		if (this.filtersFromUrl) {
			this.scrollToGrid();
		}
		await this.getDeviceFilters();
		await this.init();
		this.initialized = true;
	},

	computed: {
		isMobile() {
			const mobileBreakpoint = 768;
			return window?.innerWidth < mobileBreakpoint;
		},
		hidePaymentOptionToggle() {
			if (
				this.filterState?.categorynames[0] == "God Som Ny" ||
				this.filterState?.categorynames[0] == "Lyd" ||
				this.filterState?.categorynames[0] == "Tilbehør" ||
				this.filterState?.categorynames[0] == "Ruter"
			) {
				return true;
			}
			return false;
		},
		badgeCategory() {
			return this.content?.badgeCategory || undefined;
		},
		unVerifiedDeliveryText() {
			return this.content?.unVerifiedDeliveryText || undefined;
		},
		computedDeviceFilters() {
			return this.deviceFilters
				.map((x) => {
					return {
						label: x.name,
						value: x.id,
					};
				})
				.sort(function (a, b) {
					if (a.label < b.label) {
						return -1;
					}
					if (a.label > b.label) {
						return 1;
					}
					return 0;
				});
		},
		deviceFilterCategories() {
			return this.component?.content?.deviceFilterCategories?.map((x) => {
				return { label: x, value: x.toLowerCase() };
			});
		},
		swapSubscriptionDisclaimerText() {
			return this.content?.swapSubscriptionDisclaimerText || undefined;
		},
		hideAccessoryFilters() {
			return !this.deviceFilterCategories?.length > 0;
		},
		productLayoutClasses() {
			if ((this.showFiltering && !this.isMobile) || (this.products?.length > 0 && this.products?.length <= 3)) {
				return this.$_SectionLayout_getLayoutClasses(3);
			}
			return this.$_SectionLayout_getLayoutClasses(this.products?.length);
		},
		computedFilters() {
			if (Object.keys(this.subcategories).length > 0) {
				return [
					{
						label: Object.keys(this.subcategories).toString(),
						items: this.subcategories[Object.keys(this.subcategories)],
					},
					...this.filters.filter((x) => x.items.length > 0),
				];
			}
			return this.filters.filter((x) => x.items.length > 0);
		},
		topLink() {
			return this.component && this.component.content.topLink && LinkViewModel(this.component.content.topLink);
		},
		showFilterControls() {
			return !this.content.hideBrandFilter || !this.content.hideCategoryFilter;
		},
		showLoading() {
			return this.loading && this.products?.length < 1;
		},
		hideControls() {
			return this.content.hideControls;
		},
		enablePaymentToggle() {
			return this.content.enablePaymentToggle;
		},
		showPagination() {
			return !this.content.hidePagination;
		},
		useMonthlyPrice() {
			return this.content.showSwapPriceFirst;
		},
		hideOutOfStockProducts() {
			return this.content.hideOutOfStock;
		},
		onlyShowSwap() {
			return this.content.onlyShowSwap || false;
		},
		onlyPrioritized() {
			return this.content?.onlyPrioritized || false;
		},
		filterTags() {
			return Object.keys(this.filterState)
				.filter((af) => this.filterState[af].length > 0)
				.map((af) => this.filterState[af].map((iaf) => `${capitalize(af)}:${iaf}`))
				.flat()
				.map((f) => f.replace("Subcategories:", ""));
		},
		subcategoriesTags() {
			const subcategoriesQuery = [];
			for (const subcategory of this.filterState.subcategories) subcategoriesQuery.push(subcategory.split(":")[1]);
			return subcategoriesQuery;
		},
		visibleFilterTags() {
			return Object.keys(this.filterState)
				.filter((af) => this.filterState[af].length > 0)
				.filter((af) => this.filters.some((f) => f.label.toLowerCase() === af))
				.map((af) => this.filterState[af].map((iaf) => `${capitalize(af)}:${iaf}`))
				.flat();
		},
		currentPage() {
			return this.page + 1;
		},
		numberOfPages() {
			return Math.ceil(this.total / this.limitResults);
		},
		hasPreconfiguredFilter() {
			return this.content.hideBrandFilter || this.content.hideCategoryFilter;
		},
		selectedFilters() {
			return this.visibleFilterTags.length ? `(${this.visibleFilterTags.length})` : "";
		},
	},
});
</script>

<style lang="scss" scoped>
.top {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	@include breakpoint(mobile) {
		flex-direction: column;

		.left {
			margin-bottom: $spacing-m;
		}
	}
}

.accessory-filters-wrapper {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	z-index: 1002;
	position: relative;

	@include breakpoint(mobile) {
		flex-direction: column;
		align-items: center;
	}
}

.accessory-filters {
	display: flex;
	flex-wrap: wrap;
	gap: $spacing-s;
	justify-content: center;
	position: relative;

	div:nth-of-type(2) {
		@media screen and (min-width: $size-screen-phone) {
			margin-right: calc($spacing-2xl - $spacing-s);
		}

		@include breakpoint(mobile) {
			margin-bottom: calc($spacing-xl - $spacing-s);
		}
	}

	.flex-basis-wrapper {
		flex-basis: 100%;
		display: flex;
		justify-content: center;

		button {
			margin-top: $spacing-s;
		}
	}

	@media screen and (min-width: $size-screen-desktop) {
		margin-bottom: calc($spacing-2xl - $spacing-s);
	}

	@include breakpoint(mobile) {
		flex-direction: column;
		margin-bottom: calc($spacing-xl - $spacing-s);
	}
}

.grid-products-filtering {
	scroll-margin-top: 60px;

	.filter-section {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include breakpoint(mobile) {
			&.empty {
				display: none;
			}
		}
	}

	:deep(.accordion-item) {
		background-color: transparent !important;

		&:not(:last-of-type) {
			border-bottom: 1px solid $color-neutrals-200-tint;
		}
	}

	.checkbox {
		flex-grow: 1;
	}

	@include breakpoint(mobile) {
		scroll-margin-top: 30px;
	}
}

.product-wrapper {
	display: flex;
	gap: 24px;
	flex-wrap: nowrap;

	@include breakpoint(mobile) {
		flex-wrap: wrap;
		width: 100%;
		min-width: 0;
	}
}

.products {
	display: grid;
	width: 100%;
	min-width: 0;
	place-items: center center;

	&.grid-columns-4 {
		grid-template-columns: repeat(auto-fill, minmax(279px, 1fr));
		gap: $spacing-l;

		@include breakpoint(mobile) {
			grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
			gap: $spacing-m;
			place-items: center center;
		}
	}
}

.filter-controls {
	display: flex;
	gap: $spacing-m;
	align-items: center;

	@include breakpoint(mobile) {
		justify-content: space-between;
		gap: $spacing-s $spacing-xs;
	}
}

.chips {
	display: flex;
	flex-wrap: wrap;
	gap: $spacing-s $spacing-s;
	margin-left: $spacing-s;
}

.filters {
	opacity: 1;
	transition: opacity 0.2s ease;
	padding: 0 15px;
	flex: 0 0 25%;
	width: 25%;

	&.loading {
		opacity: 0.4;
	}

	@include breakpoint(mobile) {
		width: 100%;
		flex: 0 0 100%;
	}
}

.slide-fade-enter-active {
	transition: all 0.3s ease-out;
	max-width: 100%;
	width: 100%;

	@include breakpoint(mobile) {
		transition: none;
	}
}

.slide-fade-leave-active {
	transition: all 0.2s ease;

	@include breakpoint(mobile) {
		transition: none;
	}
}

.slide-fade-enter-from,
.slide-fade-leave-to {
	opacity: 0;
	transform: translateX(-20px);
	max-width: 0;

	@include breakpoint(mobile) {
		transition: none;
	}
}

.chips {
	:deep(svg) {
		box-sizing: content-box;
	}
}

.filter-button {
	background-color: transparent;
	border-radius: 8px !important;
	color: $color-cta-default;
	border: 2px solid $color-cta-default;
	padding: 5px 30px;
}

.loading {
	@include loading;
}

.fixed {
	background-color: white;
	position: sticky;
	padding: $spacing-s;
	transition: all 0.3s ease;
	width: 100%;
	z-index: 1000;
}
</style>
