<template>
	<div class="toggleOption">
		<TnParagraph>Betal alt nå</TnParagraph>
		<TnToggle
			v-model="paymentOption"
			class="toggle"
		/>
		<TnParagraph>{{ downPaymentOption }}</TnParagraph>
	</div>
</template>

<script>
export default defineNuxtComponent({
	name: "PaymentOptionToggle",

	props: {
		showSwapPriceFirst: {
			type: Boolean,
			default: false,
		},
		category: {
			type: Array,
			default: () => [],
		},
	},

	data() {
		return {
			paymentOption: false,
		};
	},

	mounted() {
		this.paymentOption = this.showSwapPriceFirst;
	},

	computed: {
		downPaymentOption() {
			const downPaymentCategories = ["Smartklokker", "Nettbrett"];
			if (this.category?.length > 1) {
				return "SWAP/Nedbetaling";
			}
			return downPaymentCategories.includes(this.category?.[0]) ? "Nedbetaling" : "SWAP månedspris";
		},
	},

	watch: {
		paymentOption: {
			handler(val) {
				this.$emit("update:option", +val);
			},
			immediate: true,
		},
	},
});
</script>

<style lang="scss" scoped>
.toggleOption {
	display: flex;
	gap: $spacing-m;
	align-items: center;
	justify-content: center;
}

:deep(.tn-toggle) {
	.track {
		background-color: $color-cta-default !important;

		&:hover {
			background-color: $color-cta-default !important;
		}
	}
}
</style>
