<template>
	<div>
		<div
			class="filter-section"
			:class="{ empty: parentCategory.count < 1 }"
			v-if="parentCategory.count > 0"
		>
			<TnCheckbox
				:selected="filterTags"
				@change="handleToggle(`${filter.label}:${parentCategory.name}`)"
				:modelValue="filterTags.includes(`${filter.label}:${parentCategory.name}`)"
				:label="parentCategory.name"
				:disabled="loading || loadingSubcategories"
				size="m"
				class="checkbox margin-bottom-s"
			/>
			<span>{{ parentCategory.count }}</span>
		</div>
		<div
			class="subcategories"
			v-for="(category, i) in currentSubcategories"
			:key="i"
		>
			<TnCheckbox
				:modelValue="`${parentCategory.name}:${category.name}`"
				:label="category.name"
				:selected="selectedSubcategories"
				size="m"
				class="checkbox margin-bottom-s"
				@change="toggleSubCategory(category.name)"
				:disabled="loading"
			/>
			<span>{{ category.count }}</span>
		</div>
	</div>
</template>
<script>
import ecommerceIntegration from "~/integrations/ecommerce-v2-integration";
export default defineNuxtComponent({
	props: [
		"parentCategory",
		"filterTags",
		"filter",
		"filterState",
		"loading",
		"selectedSubcategories",
		"subcategoriesCache",
	],

	data() {
		return {
			subcategories: [],
			active: false,
			loadingSubcategories: false,
		};
	},

	watch: {
		loading: function (loading) {
			if (!this.filterState.categorynames.includes(this.parentCategory.name) && this.active) {
				this.active = false;
			}
			if (!loading && this.activeCategory.length > 0) {
				this.loadSubcategories();
				this.active = true;
			}
			if (!this.active) {
				return (this.subcategories = []);
			}
		},
	},

	methods: {
		async loadSubcategories() {
			if (useRoute()?.name === "sok") {
				return;
			}
			try {
				this.loadingSubcategories = true;
				const results = await ecommerceIntegration.getSubcategories(
					this.$store.getters["ecommerce/segment"],
					this.activeCategory,
					this.filterState.brand?.join(","),
				);

				this.subcategories = results.subcategories.filter((x) => x.name !== this.parentCategory.name);
				this.loadingSubcategories = false;
			} catch (err) {
				console.error(err);
			} finally {
				this.$emit("updateSubcategories", this.subcategories, this.parentCategory.name);
			}
		},
		handleToggle(filterTag) {
			if (!this.active && filterTag.split(":")[0] == "Categorynames") {
				this.active = true;
			} else this.active = false;
			this.$emit("change", filterTag);
		},
		toggleSubCategory(subCategory) {
			this.$emit("toggleSubCategory", `${this.parentCategory.name}:${subCategory}`);
		},
	},

	computed: {
		activeCategory() {
			return this.filterState.categorynames.filter((x) => x == this.parentCategory.name);
		},
		currentSubcategories() {
			return this.subcategories;
		},
		parentCategoryLabel() {
			// let selectedSubcategories = this.selectedSubcategories.filter(x => x.includes(this.parentCategory.name)).length
			// return selectedSubcategories > 0 ? `${this.parentCategory.name} (${selectedSubcategories})` : this.parentCategory.name
		},
	},

	mounted() {
		this.subcategories = this.subcategoriesCache[this.parentCategory.name] || [];
		if (this.activeCategory.length > 0) {
			this.loadSubcategories();
			this.active = true;
		}
	},
});
</script>
<style lang="scss" scoped>
.filter-section {
	display: flex;
	justify-content: space-between;
	align-items: center;

	@include font-text-m;

	@include breakpoint(mobile) {
		&.empty {
			display: none;
		}
	}
}

.subcategories {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.checkbox {
		margin-left: $spacing-l;
	}
}
</style>
